import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import EnvironnementIndustryContainer from '@/containers/EnvironnementIndustry/EnvironnementIndustryContainer';

import { EnvironnementIndustryPageQuery } from '../../../graphql-types';

interface Props {
  data: EnvironnementIndustryPageQuery;
}

export const query = graphql`
  query EnvironnementIndustryPage {
    prismicEnvironnementIndustryPage {
      data {
        meta_title
        meta_description
        ...EnvironnementIndustryHero
        ...EnvironnementIndustryPresentation
      }
    }
    prismicEnvironnementIndustryPageBody1Companystats {
      primary {
        type
      }
      items {
        number {
          raw
        }
        label {
          text
        }
      }
    }
    prismicEnvironnementIndustryPageBodyContactbox {
      primary {
        type
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
  }
`;

const Environnement = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } =
    data?.prismicEnvironnementIndustryPage?.data || {};
  return (
    <Layout invertNav type="usine">
      <Seo
        title={meta_title || 'title'}
        description={meta_description || 'description'}
      />
      <EnvironnementIndustryContainer data={data} type="usine" />
    </Layout>
  );
};

export default Environnement;
