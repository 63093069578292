import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import EnvironnementIndustryHero from '@/containers/EnvironnementIndustry/EnvironnementIndustryHero';
import EnvironnementIndustryStats from '@/containers/EnvironnementIndustry/EnvironnementIndustryStats';
import EnvironnementIndustryPresentation from '@/containers/EnvironnementIndustry/EnvironnementIndustryPresentation';
import EnvironnementIndustryContactBox from '@/containers/EnvironnementIndustry/EnvironnementIndustryContactBox';

import {
  EnvironnementIndustryPageQuery,
  PrismicEnvironnementIndustryPageEnvironnementGroupType,
} from '../../../graphql-types';

interface Props {
  data: EnvironnementIndustryPageQuery;
  type: string;
}

const EnvironnementIndustryContainerRoot = styled.section`
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  background-color: ${({ theme }) => theme.color.black.main};
`;

const EnvironnementIndustryContainer = ({ data, type }: Props): JSX.Element => {
  const {
    hero_title,
    hero_content,
    hero_background,
    environnement,
    ambition_title,
    ambition_content,
  } = data?.prismicEnvironnementIndustryPage?.data;

  const { primary } =
    data?.prismicEnvironnementIndustryPageBodyContactbox || {};

  const companyStats =
    data?.prismicEnvironnementIndustryPageBody1Companystats || {};

  const HeroProps = {
    title: <RichText render={hero_title?.raw} />,
    content: <RichText render={hero_content?.raw} />,
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
  };

  const EnvironnementProps = {
    environnement: environnement?.map(
      (el: PrismicEnvironnementIndustryPageEnvironnementGroupType | null) => {
        const { environnement_title, environnement_content } = el || {};

        return {
          title: <RichText render={environnement_title?.raw} />,
          content: <RichText render={environnement_content?.raw} />,
        };
      },
    ),
    ambition: {
      title: <RichText render={ambition_title?.raw} />,
      content: <RichText render={ambition_content?.raw} />,
    },
  };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  return (
    <EnvironnementIndustryContainerRoot>
      <EnvironnementIndustryHero {...HeroProps} />
      <EnvironnementIndustryPresentation {...EnvironnementProps} />
      {primary && <EnvironnementIndustryContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && (
        <EnvironnementIndustryStats {...StatsProps} />
      )}
    </EnvironnementIndustryContainerRoot>
  );
};

export default EnvironnementIndustryContainer;
